<template>
  <el-dialog title="设置关联门店" :visible.sync="is_show" width="600px" :before-close="handleClose">
    <div class="list">
      <el-checkbox v-for="(item, index) in list" :key="index" @change="selectChange" :disabled="item.disabled" v-model="item.checked">
        {{ item.store_name }}
      </el-checkbox>
    </div>
    <div class="total">
      <el-checkbox v-model="is_total" @change="totalChange">全选</el-checkbox>
      <span v-show="selectNum">已选中{{ selectNum }}个店铺</span>
    </div>
    <!-- <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="rows"
        layout="total, prev, pager, next"
        :total="total_number"
      ></el-pagination>
    </div> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      is_total: !1,
      page: 1,
      rows: 10000,
      list: [],
      selectNum: 0,
      selectStore: [],
      total_number: 0,
    };
  },
  props: {
    is_show:{
      default:!1
    },
    selectedStore:{
      default:[]
    }
  },
  watch: {
    is_show(val) {
      if (val && !this.list.length) this.getStoreList();
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getStoreList();
    },
    totalChange(val) {
      if (val) {
        this.list.map((item) => (item.checked = !0));
        this.selectStore = this.list;
        this.selectNum = this.list.length;
      } else {
        this.list.map((item) => (item.checked = !1));
        this.selectStore = [];
        this.selectNum = 0;
      }
    },
    selectChange() {
      let list = this.list.filter((item) => item.checked);
      this.selectNum = list.length;
      this.selectStore = list;
    },
    cancel() {
      this.is_show = !1;
      this.$emit('closeAddStore');
    },
    handleClose() {
      this.cancel();
    },
    confirm() {
      this.$emit('getStore', this.selectStore);
    },
    getStoreList() {
      this.$axios
        .post(this.$api.store.storeList, {
          // page: this.page,
          rows: this.rows,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            for (let i in list) list[i].checked = !1;
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  padding: 20px;
  height: 250px;
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.total {
  padding: 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #ebf0f3;
  span {
    margin-left: 10px;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
</style>
